import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Top from '../components/top'
import Footer from '../components/footer'
import Header from "../components/header"
import Form from '../components/form'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const ContactPage = ({data}) => {
  const entry = data.prismicContactPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Top />
      <Header />

      <div className="w-full py-16 relative">
        <div className="absolute -inset-0">
          <GatsbyImage className="w-full h-full object-cover block" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
        </div>
        <div className="absolute -inset-0">
          <div className="w-full h-full bg-black opacity-50"></div>
        </div>
        <div className="relative">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="flex-none sm:flex justify-between gap-16">
              <div className="w-full sm:w-8/12">
                <div className="text-4xl sm:text-5xl text-white">
                  <h1><span className="text-white mr-3">|</span>{entry.data.heading}</h1>
                </div>
              </div>
              <div className="hidden sm:w-4/12">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-4">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex items-center justify-end gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
            <Link to="/">Home</Link>
          </div>
        </div>
      </div>

      <div className="w-full py-16 bg-white border-t border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-xl relative">

          <div className="w-full mb-12 text-grey-6">
            <div className="mb-3">
              <p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline-block w-6 h-6 mr-3"><path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" /></svg> 
              <a href="mailto:info@firststar-tech.com" title="info@firststar-tech.com" rel="nofollow noopener" className="hover:text-red">info@firststar-tech.com</a></p>  
            </div>
            <div>
              <p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline-block w-6 h-6 mr-3"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" /></svg>
              <a href="tel:+8675528857031" title="+86 (7) 5528857031" rel="nofollow noopener" className="hover:text-red">+86 (7) 5528857031</a></p>  
            </div>
          </div>

          <div className="flex-none sm:flex justify-between gap-16">
            <div className="w-full sm:w-6/12">
              <div className="mb-6 prose prose-lg prose-h1:font-display prose-h1:text-4xl prose-h1:font-normal prose-h2:font-normal prose-h3:font-normal prose-h4:font-normal prose-h5:font-normal prose-h6:font-normal">
                <PrismicRichText field={entry.data.text.richText} />
              </div>
              <Form />
            </div>
            <div className="w-full sm:w-6/12 mt-12 sm:mt-0">
              <figure data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
                <GatsbyImage className="rounded-xl shadow-xl aspect-w-1 aspect-h-1 w-full h-full object-cover block" image={entry.data.image2.gatsbyImageData} alt={entry.data.image2.alt ?? ''} />
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pt-16 pb-8 border-t border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="text-4xl">
            <h3>Our location</h3>
          </div>
        </div>
      </div>
      <div className="w-full">
        <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14724.3641703244!2d114.1587772!3d22.6876545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404767532da8aa9%3A0x5b93edf9d3a24108!2zMjLCsDQxJzI2LjUiTiAxMTTCsDEwJzA0LjAiRQ!5e0!3m2!1sen!2snz!4v1661888364739!5m2!1sen!2snz" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicContactPage {
    data {
      meta_description
      page_title
      heading
      image {
        gatsbyImageData
        alt
      }
      image2 {
        gatsbyImageData
        alt
      }
      text {
        richText
      }
    }
  }
}
`

export default ContactPage
