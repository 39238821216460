import React from 'react'
import { useState } from 'react';
import Validate from './validation.js';
import FieldText from './field_text.js';
import FieldEmail from './field_email.js';
import FieldTextArea from './field_textarea.js';
import FieldErrorMessage from './field_error_message';
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = '6LcMf6EhAAAAAPTXkNZbd-1gDbtqQNJ3WQ2ogBVP';

const FormEnquiry = () => 
{
    const recaptchaRef = React.createRef();
    const [formValues, setFormValues] = useState({
        formErrors: '',
        formIsValid: false,
        formControls: {
            name: {
                type: 'text',
                value: '',
                placeholder: 'Your Name...*',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired:  true,
                },
                errorMessages: ''
            },
            email: {
                type: 'email',
                value: '',
                placeholder: 'E-mail address...*',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true,
                    isEmail: true
                },
                errorMessages: ''
            },
            company: {
                type: 'text',
                value: '',
                placeholder: 'Company Name',
                valid: true,
                touched: false,
                validationRules: '',
                errorMessages: ''
            },
            country: {
                type: 'text',
                value: '',
                placeholder: 'Country/Region...*',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true
                },
                errorMessages: ''
            },
            enquiry: {
                type: 'text',
                value: '',
                placeholder: 'Message',
                valid: true,
                touched: false,
                validationRules: '',
                errorMessages: ''
            },
        }
    });

    const changeHandler = e => {
        const name = e.target.name;
        const value = e.target.value;

        const updatedControls = {
            ...formValues.formControls
        };
        const updatedFormElement = {
            ...updatedControls[name]
        };
        updatedFormElement.value = value;
        updatedFormElement.touched = true;

        let validateFormElement = [];
        validateFormElement = Validate(value, updatedFormElement.validationRules);

        updatedFormElement.valid = validateFormElement.isValid;
        updatedFormElement.errorMessages = validateFormElement.errorMessages;

        updatedControls[name] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }

        setFormValues({
            ...formValues,
            formControls: updatedControls,
            formIsValid: formIsValid,
        });
    }

    const submitHandler = async e => 
    {
        e.preventDefault();
        e.persist();
        
        const token = await recaptchaRef.current.executeAsync();
        const form = e.target;
        const formData = {};

        for (let formElementId in formValues.formControls) {
            formData[formElementId] = formValues.formControls[formElementId].value
        }
        
        const encode = (formData) => {
            return Object.keys(formData)
                .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(formData[key]))
                .join("&");
        }

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ 
                "form-name": form.getAttribute("name"),
                "g-recaptcha-response": token,
                ...formData
            })
        })
        .then(() => window.location.href = '/contact/thanks/')
        .catch(error => alert(error));
    }

    return (
        <form className="font-sans text-base" name="contact" method="post" action="/contact/thanks/" data-netlify="true" data-netlify-recaptcha="true" onSubmit={submitHandler}>
            <label htmlFor="name" className="hidden font-sans prose md:prose-lg pb-1 block">Name:<span className="text-red">*</span></label>
            <FieldText
                id="name"
                name="name"
                type={formValues.formControls.name.type}
                placeholder={formValues.formControls.name.placeholder}
                value={formValues.formControls.name.value}
                onChange={changeHandler}
                className={formValues.formControls.name.touched && !formValues.formControls.name.valid ? 'prose md:prose-lg w-full px-2 py-1 border border-red mb-1 focus:outline-none' : 'prose md:prose-lg w-full px-2 py-1 border border-grey-c mb-4 focus:border-red focus:outline-none focus:ring-0'}
            />
            <FieldErrorMessage
                className={formValues.formControls.name.touched && !formValues.formControls.name.valid ? 'block prose md:prose-lg text-red mb-4' : 'hidden'}
                errorMessages={formValues.formControls.name.errorMessages}
            />
            <label htmlFor="email" className="hidden font-sans prose md:prose-lg pb-1 block">Email:<span className="text-red">*</span></label>
            <FieldEmail
                id="email"
                name="email"
                type={formValues.formControls.email.type}
                placeholder={formValues.formControls.email.placeholder}
                value={formValues.formControls.email.value}
                onChange={changeHandler}
                className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'prose md:prose-lg w-full px-2 py-1 border border-red mb-1 focus:outline-none' : 'prose md:prose-lg w-full px-2 py-1 border border-grey-c mb-4 focus:border-red focus:outline-none focus:ring-0'}
            />
            <FieldErrorMessage
                className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'block prose md:prose-lg text-red mb-4' : 'hidden'}
                errorMessages={formValues.formControls.email.errorMessages}
            />
            <div className="flex gap-3">
                <div className="w-6/12">
                    <label htmlFor="company" className="hidden font-sans prose md:prose-lg pb-1 block">Company:</label>
                    <FieldText
                        id="company"
                        name="company"
                        type={formValues.formControls.company.type}
                        placeholder={formValues.formControls.company.placeholder}
                        value={formValues.formControls.company.value}
                        onChange={changeHandler}
                        className={formValues.formControls.company.touched && !formValues.formControls.company.valid ? 'prose md:prose-lg w-full px-2 py-1 border border-red mb-1 focus:outline-none' : 'prose md:prose-lg w-full px-2 py-1 border border-grey-c mb-4 focus:border-red focus:outline-none focus:ring-0'}
                    />
                    <FieldErrorMessage
                        className={formValues.formControls.company.touched && !formValues.formControls.company.valid ? 'prose md:prose-lg text-red mb-4' : 'hidden'}
                        errorMessages={formValues.formControls.company.errorMessages}
                    />
                </div>
                <div className="w-6/12">
                    <label htmlFor="country" className="hidden font-sans prose md:prose-lg pb-1 block">Country/Region:<span className="text-red">*</span></label>
                    <FieldText
                        id="country"
                        name="country"
                        type={formValues.formControls.country.type}
                        placeholder={formValues.formControls.country.placeholder}
                        value={formValues.formControls.country.value}
                        onChange={changeHandler}
                        className={formValues.formControls.country.touched && !formValues.formControls.country.valid ? 'prose md:prose-lg w-full px-2 py-1 border border-red mb-1 focus:outline-none' : 'prose md:prose-lg w-full px-2 py-1 border border-grey-c mb-4 focus:border-red focus:outline-none focus:ring-0'}
                    />
                    <FieldErrorMessage
                        className={formValues.formControls.country.touched && !formValues.formControls.country.valid ? 'prose md:prose-lg text-red mb-4' : 'hidden'}
                        errorMessages={formValues.formControls.country.errorMessages}
                    />
                </div>
            </div>
            <label htmlFor="enquiry" className="hidden font-sans prose md:prose-lg pb-1 block">Enquiry:</label>
            <FieldTextArea
                id="enquiry"
                name="enquiry"
                type={formValues.formControls.enquiry.type}
                placeholder={formValues.formControls.enquiry.placeholder}
                value={formValues.formControls.enquiry.value}
                onChange={changeHandler}
                className={formValues.formControls.enquiry.touched && !formValues.formControls.enquiry.valid ? 'prose md:prose-lg w-full px-2 py-1 border border-red h-40 focus:outline-none' : 'prose md:prose-lg w-full px-2 py-1 border border-grey-c mb-4 h-40 focus:border-red focus:outline-none focus:ring-0'}
            />
            <FieldErrorMessage
                className={formValues.formControls.enquiry.touched && !formValues.formControls.enquiry.valid ? 'block prose md:prose-lg text-red mb-4' : 'hidden'}
                errorMessages={formValues.formControls.enquiry.errorMessages}
            />
            <FieldErrorMessage
                className={formValues.formErrors ? 'block field__errors' : 'hidden field__errors'}
                errorMessages={formValues.formErrors}
            />
            <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={SITE_KEY}
            />
            <button type="submit"
                className="font-sans font-bold prose md:prose-lg py-1 px-3 inline-block bg-red text-white focus:outline-none disabled:bg-grey-e disabled:text-grey-9 disabled:cursor-not-allowed"
                disabled={!formValues.formIsValid}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="inline-block mr-2 w-6 h-6"><path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" /></svg>
 Send Message
            </button>
        </form>
    )
}

export default FormEnquiry